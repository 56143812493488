import React from "react"

import { Button, Container, Navbar, Nav } from "react-bootstrap"

const Navigation = (props) => {
  return (
    <Navbar
      id="mainNav"
      className="py-3"
      variant="light"
      fixed="top"
      expand="lg"
      collapseOnSelect
    >
      <Container>
        <Navbar.Brand href="#page-top" onClick={props.handleScroll}>
          <i className="fas fa fa-hotel text-primary"></i> Regent Motel
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="Responsive-NavBar" />
        <Navbar.Collapse id="Responsive-NavBar">
          <Nav className="mx-auto">
            <Nav.Item>
              <Nav.Link href="#about" onClick={props.handleScroll}>
                About
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#accomodations" onClick={props.handleScroll}>
                Accomodations
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#amenities" onClick={props.handleScroll}>
                Amenities
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#location" onClick={props.handleScroll}>
                Location
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#gallery" onClick={props.handleScroll}>
                Gallery
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#contact" onClick={props.handleScroll}>
                Contact
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                target="_blank"
                rel="noreferrer"
                href="https://www.google.ca/travel/hotels/entity/CgoI2JDjxuPtvbtyEAE/reviews"
              >
                Reviews
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <Button
          target="_blank"
          rel="noreferrer"
          href="https://www.beds24.com/booking.php?propid=93975&referer=BookingLink"
        >
          Book Now
        </Button>
      </Container>
    </Navbar>
  )
}

export default Navigation
