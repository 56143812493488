import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

const About = (props) => {
  return (
    <section className="page-section bg-primary" id="about">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <h2 className="text-white mt-0">About Regent Motel</h2>
            <hr className="divider light my-4" />
            <p className="text-white-75 mb-4">
              This family owned business welcomes their guests and helps them
              feel at home upon their stay at the motel. We work to make sure
              our rooms are clean and comfortable, and offer to our guests
              reasonable price for their stay.
            </p>
            <p className="text-white-75 mb-4">
              Regent motel offers the comfortable and enjoyable stay with very
              affordable rates operating seven days a week. Rooms are furnished
              and offering amenities such as Free Wi-Fi, Microwave, Fridge and
              LCD TV with premium channels. Kids 10 and under stay for free.
            </p>
            <p className="text-white-75 mb-4">
              The Motel is located on the Westside of Saint John, only a few
              minutes off from <strong>Exit 96</strong> when coming from{" "}
              <strong>Highway 7</strong> and <strong>Exit 114</strong> or{" "}
              <strong>Exit 112</strong> when coming in from{" "}
              <strong>Highway 1</strong>, respectively. The Motel is only a 10
              minutes drive from uptown Saint John.
            </p>
            <div className="flex-sm-wrap">
              <Button
                variant="light"
                size="xl"
                href="#location"
                onClick={props.handleScroll}
                className="mx-2 my-2"
              >
                View Map
              </Button>
              <Button
                variant="light"
                size="xl"
                href="#accomodations"
                onClick={props.handleScroll}
                className="mx-2 my-2"
              >
                Explore all our rooms
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default About
