import React from "react"
import { Container, Row, Col, ListGroup } from "react-bootstrap"

const Amenities = () => {
  return (
    <section className="page-section text-primary" id="amenities">
      <Container>
        <h2 className="text-center mt-0">Amenities</h2>
        <hr className="divider primary my-4" />
        <Row className="justify-content-center text-center">
          <Col lg={3} md={6} className="text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-leaf text-primary mb-4"></i>
              <h4 className="mb-2">Outdoors</h4>
              <ListGroup variant="flush" className="text-muted mb-0">
                <ListGroup.Item>Garden</ListGroup.Item>
                <ListGroup.Item>Picnic Table</ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
          <Col lg={3} md={6} className="text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-paw text-primary mb-4"></i>
              <h4 className="mb-2">Pets</h4>
              <ListGroup variant="flush" className="text-muted mb-0">
                <ListGroup.Item>No pets allowed</ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
          <Col lg={3} md={6} className="text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-wifi text-primary mb-4"></i>
              <h4 className="mb-2">Wifi</h4>
              <ListGroup variant="flush" className="text-muted mb-0">
                <ListGroup.Item>Avaliable in all areas</ListGroup.Item>
                <ListGroup.Item>Free of Charge</ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
          <Col lg={3} md={6} className="text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-theater-masks text-primary mb-4"></i>
              <h4 className="mb-2">Entertainment</h4>
              <ListGroup variant="flush" className="text-muted mb-0">
                <ListGroup.Item>Kid's TV Channels</ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6} className="text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-concierge-bell text-primary mb-4"></i>
              <h4 className="mb-2">Services</h4>
              <ListGroup variant="flush" className="text-muted mb-0">
                <ListGroup.Item>Snacks & Drinks</ListGroup.Item>
                <ListGroup.Item>Private check-in/out</ListGroup.Item>
                <ListGroup.Item>Fax/Photocopying</ListGroup.Item>
                <ListGroup.Item>24-hour front desk</ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
          <Col lg={3} md={6} className="text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-parking text-primary mb-4"></i>
              <h4 className="mb-2">Parking</h4>
              <ListGroup variant="flush" className="text-muted mb-0">
                <ListGroup.Item>
                  Free public parking is available on site
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
          <Col lg={3} md={6} className="text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-user-shield text-primary mb-4"></i>
              <h4 className="mb-2">Safety & Security</h4>
              <ListGroup variant="flush" className="text-muted mb-0">
                <ListGroup.Item>Fire extinguishers</ListGroup.Item>
                <ListGroup.Item>CCTV outside property</ListGroup.Item>
                <ListGroup.Item>Smoke alarms</ListGroup.Item>
                <ListGroup.Item>24-hour security</ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
          <Col lg={3} md={6} className="text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-info-circle text-primary mb-4"></i>
              <h4 className="mb-2">General Info</h4>
              <ListGroup variant="flush" className="text-muted mb-0">
                <ListGroup.Item>Heating</ListGroup.Item>
                <ListGroup.Item>Non-smoking rooms</ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Amenities
