/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import { Container, Col, Row } from "react-bootstrap"

const getScrollNode = (element) => {
  return (
    element.ownerDocument.scrollingElement ||
    element.ownerDocument.documentElement
  )
}

const isScrolled = (element) => {
  const scrollNode = getScrollNode(element)
  return scrollNode.scrollTop > 0
}

export default class Layout extends Component {
  constructor(props) {
    super(props)
    this.siteContainer = React.createRef()
    this.state = {
      scrolled: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  render() {
    let className = "site-container"
    if (this.props.className) className += ` ${this.props.className}`
    if (this.state.scrolled) className += " navbar-scrolled"

    return (
      <div className={className} ref={this.siteContainer} id="page-top">
        <main>{this.props.children}</main>
        <footer className="bg-dark py-4">
          <Container>
            <Row className="small text-center text-white-50">
              <Col lg={6} sm={12}>
                Design & Developed by Shivam Sabbarwal
              </Col>
              <Col lg={6} sm={12}>
                {`Copyright © ${new Date().getFullYear()} - Regent Motel `}
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
