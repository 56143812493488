import React from "react"
import { Container, Button } from "react-bootstrap"

const Attractions = () => {
  const googleAPIKey = "AIzaSyDL986a08Mtz75kyo7VElvnANSkH3dhrL8"
  const regentMotelPlacesId = "ChIJr60cDspKpkwRWMjYOG73dnI"

  return (
    <section className="page-section bg-dark text-white" id="location">
      <Container className="text-center">
        <h2 className="mt-0">Get Directions</h2>
        <hr className="divider light my-4" />
        <Container className="ratio ratio-16x9">
          <iframe
            title="The Map"
            src={`https://www.google.com/maps/embed/v1/place?q=place_id:${regentMotelPlacesId}&key=${googleAPIKey}`}
            allowFullScreen
          ></iframe>
        </Container>
        <div className="mt-5 justify-content-center">
          <Button
            size="lg"
            href="https://www.discoversaintjohn.com/things-do"
            target="__blank"
            rel="noreferrer"
            variant="outline-light"
          >
            See Local Attractions
          </Button>
        </div>
      </Container>
    </section>
  )
}

export default Attractions
