import React from "react"

import { Modal, Button, ListGroup, Row, Col } from "react-bootstrap"

const RoomInfo = (props) => {
  return (
    <Modal
      {...props}
      id="room-modal"
      size="lg"
      aria-labelledby="room-modal"
      centered
    >
      <Modal.Header className="d-inline text-center">
        <Modal.Title>
          <h2 className="text-center my-3">Features</h2>
          <hr className="divider primary" />
        </Modal.Title>
        <Row className="justify-content-center text-center">
          <Col className="text-center">
            <div className="mt-5">
              <i className="fas fa-2x fa-user-friends text-primary mb-4"></i>
              <h6>Max Guest(s)</h6>
              <p className=" mb-2">{props.maxGuests}</p>
              <p className="text-muted small">{props.rates}</p>
            </div>
          </Col>
          <Col className="text-center">
            <div className="mt-5">
              <i className="fas fa-2x fa-bed text-primary mb-4"></i>
              <h6>Bed Type(s)</h6>
              <p className=" mb-2">{props.bedType}</p>
            </div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Row className="justify-content-center text-center">
          <Col>
            <ListGroup variant="flush">
              <ListGroup.Item>Flat-screen Cable TV</ListGroup.Item>
              <ListGroup.Item>Radio & Alarm Clock</ListGroup.Item>
              <ListGroup.Item>Refrigerator</ListGroup.Item>
              <ListGroup.Item>Microwave</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <ListGroup variant="flush">
              <ListGroup.Item>Private Bathroom</ListGroup.Item>
              <ListGroup.Item>Tolitries & Towels</ListGroup.Item>
              <ListGroup.Item>Desk & Chair</ListGroup.Item>
              <ListGroup.Item>Sitting Area</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center text-center">
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RoomInfo
