import React from "react"
import { Button, Container, Row, Col } from "react-bootstrap"

const Header = (props) => {
  return (
    <header className="masthead vh-100 d-flex align-items-center justify-content-center">
      <Container fluid>
        <Row className="align-items-center justify-content-center text-center">
          <Col lg={10} className="align-self-center">
            <h1 className="text-white font-weight-bold">Regent Motel</h1>
            <hr className="divider my-4" />
          </Col>
          <div className="col-lg-8 align-self-baseline">
            <p className="text-white mb-5">
              Regent Motel has rooms to accommodate almost any size families or
              traveling groups. This family owned and operated business offers
              comfortable, affordable accommodation units, free wireless
              internet, and much more.
            </p>
            <div className="flex-sm-wrap">
              <Button
                variant="light"
                size="xl"
                className="mx-2 my-2"
                href="#about"
                onClick={props.handleScroll}
              >
                About Us
              </Button>
              <Button
                size="xl"
                className="mx-2 my-2"
                rel="noreferrer"
                target="_blank"
                href="https://www.beds24.com/booking.php?propid=93975&referer=BookingLink"
              >
                Book Now
              </Button>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  )
}

export default Header
