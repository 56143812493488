import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Navigation from "../components/navigation"
import Header from "../components/header"
import About from "../components/about"
import Accomodations from "../components/accomodations"
import Gallery from "../components/gallery"
import Amenities from "../components/amenities"
import Location from "../components/location"
import Contact from "../components/contact"

import "../sass/index.scss"

export default class IndexPage extends Component {
  handleScroll = (e) => {
    e.preventDefault()
    const element = e.target
    const id = element.getAttribute("href")
    const document =
      element.ownerDocument.scrollingElement ||
      element.ownerDocument.documentElement
    const scrollNode = document.querySelector(id)
    const top = scrollNode.getBoundingClientRect().top
    const scrollTop = window.pageYOffset || document.scrollTop
    const fixedHeight = 35
    const scrollTo = top + scrollTop - fixedHeight
    if (document.scrollTo) {
      document.scrollTo({
        top: scrollTo,
        left: 0,
        behavior: "smooth",
      })
    } else {
      document.scrollTop = scrollTo
    }
  }

  render() {
    return (
      <Layout>
        <SEO />
        <Navigation handleScroll={this.handleScroll} />
        <Header handleScroll={this.handleScroll} />
        <About handleScroll={this.handleScroll} />
        <Accomodations handleScroll={this.handleScroll} />
        <Amenities />
        <Location />
        <Gallery />
        <Contact />
      </Layout>
    )
  }
}
