import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const Contact = () => {
  return (
    <section className="page-section bg-primary" id="contact">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <h2 className="text-white mt-0">Give us call!</h2>
            <hr className="divider light my-4" />
            <p className="text-white-75 mb-5">
              Want to book a reservation? Give us a call or send us an email and
              we will get back to you as soon as possible!
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="mx-auto text-center mb-5 my-lg-0">
            <i className="fas fa-phone fa-3x mb-3 text-white"></i>
            <p className="d-block text-white-75">Office: +1 (506) 672-8273</p>
            <p className="d-block text-white-75">Toll Free: +1 (888) 672-8273</p>
          </Col>
          <Col lg={4} className="mx-auto text-center mb-5 my-lg-0">
            <i className="fas fa-envelope fa-3x mb-3 text-white"></i>
            <a
              className="d-block text-white-75"
              href="mailto:regentmotel@yahoo.com"
            >
              regentmotel@yahoo.com
            </a>
          </Col>
          <Col lg={4} className="mx-auto text-center">
            <i className="fas fa-map-marker-alt fa-3x mb-3 text-white"></i>
            <div className="d-block text-white-75">
              <p>
                2121 Ocean Westway, Saint John, New Brunswick E2M 5H6, Canada
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact
