import React from "react"
import ImageGallery from "react-image-gallery"
import { Container, Row, Col } from "react-bootstrap"

const getImgPath = (num) => require(`../images/gallery/${num}.jpg`).default

const Images = [...Array(22)].map((_, index) => ({
  original: getImgPath(index + 1),
  thumbnail: getImgPath(index + 1),
}))

const Gallery = () => {
  return (
    <section className="page-section text-primary" id="gallery">
      <Container className="text-center">
        <h2 className="mt-0">Gallery</h2>
        <hr className="divider primary my-4" />
        <Row className="justify-content-center">
          <Col>
            <ImageGallery
              items={Images}
              showBullets
              showPlayButton={false}
              slideDuration={1000}
              lazyLoad
              showFullscreenButton={false}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Gallery
