import React, { useState } from "react"
import { Card, Button, Row, Col, Container } from "react-bootstrap"

import RoomInfo from "./roomInfo"

import QBedImg from "../images/gallery/16.jpg"
import S1BedImg from "../images/gallery/15.jpg"
import S2BedImg from "../images/gallery/11.jpg"

const Accomodations = (props) => {
  const [showQueen, setShowQueen] = useState(false)
  const [showSingle, setShowSingle] = useState(false)
  const [showTwin, setShowTwin] = useState(false)

  return (
    <section className="page-section bg-dark text-white" id="accomodations">
      <Container className="text-center" fluid>
        <h2 className="mt-0">Accomodations</h2>
        <hr className="divider light my-4" />
        <Row className="justify-content-center">
          <Col lg={4} md={6} className="my-4">
            <Card className="bg-dark text-white">
              <Card.Img variant="top" src={QBedImg} alt="Room Image" />
              <Card.Header>
                <Card.Title>Queen Bedroom</Card.Title>
                <Button
                  variant="outline-light"
                  size="lg"
                  className="mx-2 my-2"
                  onClick={() => setShowQueen(true)}
                >
                  More Info
                </Button>
                <RoomInfo
                  show={showQueen}
                  onHide={() => setShowQueen(false)}
                  maxGuests={2}
                  bedType="1 Queen Bed"
                />
                <Button
                  size="lg"
                  className="mx-2 my-2"
                  href="https://www.beds24.com/booking.php?propid=93975&referer=BookingLink"
                  target="__blank"
                >
                  Book Room
                </Button>
              </Card.Header>
            </Card>
          </Col>
          <Col lg={4} md={6} className="my-4">
            <Card className="bg-dark text-white">
              <Card.Img variant="top" src={S1BedImg} alt="Room Image" />
              <Card.Header>
                <Card.Title>Single Standard Bedroom</Card.Title>
                <Button
                  variant="outline-light"
                  size="lg"
                  className="mx-2 my-2"
                  onClick={() => setShowSingle(true)}
                >
                  More Info
                </Button>
                <RoomInfo
                  show={showSingle}
                  onHide={() => setShowSingle(false)}
                  maxGuests={2}
                  bedType="1 Double Bed"
                />
                <Button
                  size="lg"
                  className="mx-2 my-2"
                  href="https://www.beds24.com/booking.php?propid=93975&referer=BookingLink"
                  target="__blank"
                >
                  Book Room
                </Button>
              </Card.Header>
            </Card>
          </Col>
          <Col lg={4} md={6} className="my-4">
            <Card className="bg-dark text-white">
              <Card.Img variant="top" src={S2BedImg} alt="Room Image" />
              <Card.Header>
                <Card.Title>Double Standard Bedroom</Card.Title>
                <Button
                  variant="outline-light"
                  size="lg"
                  className="mx-2 my-2"
                  onClick={() => setShowTwin(true)}
                >
                  More Info
                </Button>
                <RoomInfo
                  show={showTwin}
                  onHide={() => setShowTwin(false)}
                  maxGuests="4*"
                  bedType="2 Double Beds"
                  rates="*Rates based on 2 guests"
                />
                <Button
                  size="lg"
                  className="mx-2 my-2"
                  href="https://www.beds24.com/booking.php?propid=93975&referer=BookingLink"
                  target="__blank"
                >
                  Book Room
                </Button>
              </Card.Header>
            </Card>
          </Col>
        </Row>
        <Button
          variant="light"
          size="xl"
          href="#amenities"
          onClick={props.handleScroll}
        >
          View Amenities
        </Button>
      </Container>
    </section>
  )
}

export default Accomodations
